import LidGenerator from "../processors/LidGenerator";
import {Button, Card, CardContent, TextField} from "@mui/material";
import React from "react";


function LidForm({updateRecordsCallback}) {
    let settings = {
        idPrefix: "L",
        division: "2",
        subDivision: "001",
        startingId: "00000001",
        idCount: 10
    }

    const getLidRecords = () => {
        let lidsFromGenerator = LidGenerator.getLids(
            settings.startingId,
            settings.idCount,
            settings.idPrefix,
            settings.division,
            settings.subDivision
        )
        return lidsFromGenerator;
    };

    let generateLIds_onClick = () => {
        updateRecordsCallback(getLidRecords());
    };

    let lidPrefix_onChange = (e) => {
        settings.idPrefix = e.target.value;
    }

    let lhmDivision_onChange = (e) => {
        settings.division = e.target.value;
    }

    let lhmSubdivision_onChange = (e) => {
        settings.subDivision = e.target.value;
    }
    let startingId_onChange = (e) => {
        settings.startingId = e.target.value;
    }

    let idCount_onChange = (e) => {
        settings.idCount = e.target.value;
    }

    return (
        <form noValidate autoComplete="off">
            <Card mb={6}>
                <CardContent>
                    <TextField
                        m={2}
                        required
                        id="lidPrefix"
                        label="ID prefix"
                        defaultValue="L"
                        onChange={lidPrefix_onChange}
                    />
                    <TextField
                        m={2}
                        required
                        id="lhmDivision"
                        label="Lighthouse Division"
                        defaultValue="2"
                        onChange={lhmDivision_onChange}
                    />
                    <TextField
                        m={2}
                        required
                        id="lhmSubdivision"
                        label="Lighthouse Subdivision"
                        defaultValue="001"
                        onChange={lhmSubdivision_onChange}
                    />
                    <TextField
                        m={2}
                        required
                        id="startingId"
                        label="Starting ID value"
                        defaultValue="0000001"
                        onChange={startingId_onChange}
                    />
                    <br/>
                    <br/>
                    <TextField
                        m={2}
                        required
                        id="idCount"
                        label="Number of IDs to Generate"
                        defaultValue="10"
                        onChange={idCount_onChange}
                    />
                    <br/>
                    <br/>
                    <Button
                        mr={2}
                        variant="contained"
                        color="primary"
                        onClick={generateLIds_onClick}
                    >
                        Generate LIDs
                    </Button>
                </CardContent>
            </Card>
        </form>
    );
}

export default LidForm;