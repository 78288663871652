import ViewportLayout from "../../../layouts/Viewport";
import MainView from "../views/main";
import LabelTemplateEditor from "../views/LabelTemplateEditor";
import React from "react";

const getRoutes = (appSettings) => {
    return {
        path: appSettings.pathPrefix,
        element: <ViewportLayout />,
        children: [
            {
                path: "main",
                element: <MainView />,
            },
            {
                path: "template-editor",
                element: <LabelTemplateEditor />,
            },
            {
                path: "registry",
                element: undefined,
            },
        ],
    };
}

export default getRoutes;