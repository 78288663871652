import MenuItems from "./initializers/menuItems";
import AppSettings from "./app.json";
import Routes from "./initializers/routes";

const applicationName = AppSettings.applicationName;
const pathPrefix = AppSettings.pathPrefix;
const serialNumber = AppSettings.serialNumber;

let addRoutes = function(routes) {
    const appRoutes = Routes(AppSettings);
    routes.push(appRoutes);
}

const getMenuItems = () => {
    return MenuItems(AppSettings);
}

let getPath = function() {
    return pathPrefix;
}

let getSerialNumber = function() {
    return serialNumber;
}

const App = {
    name: applicationName,
    addRoutes: addRoutes,
    getPath: getPath,
    getSerialNumber: getSerialNumber,
    getMenuItems: getMenuItems
};

export default App;