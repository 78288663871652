
import React from "react";
import styled from "@emotion/styled";
// import {useTranslation} from "react-i18next";


const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const MainView = () => {
    // const { t } = useTranslation();
    return (
        <Root>
            <span>Lightrail TODO list...</span>
        </Root>
    );
};

export default MainView;