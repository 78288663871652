import React from "react";
import styled from "@emotion/styled";

import {
    Button,
    ListItemIcon,
    Menu,
    MenuItem,
    ListItemText as MuiListItemText, Link
} from "@mui/material";
import {
    Drafts as DraftsIcon,
    MoveToInbox as InboxIcon,
    Send as SendIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const ListItemText = styled(MuiListItemText)(spacing);


function AppMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <span>
                    <Button
                        aria-owns={anchorEl ? "icon-menu" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        variant="contained"
                        color="secondary"
                    >
                        Apps...
                    </Button>
                    <Menu
                        id="icon-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            component={Link}
                            href="/beacon/main"
                        >
                            <ListItemIcon>
                                <SendIcon />
                            </ListItemIcon>
                            <ListItemText inset primary="Start" pl={0} />
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            href="/lids/main"
                        >
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText inset primary="ID Manager" pl={0} />
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText inset primary="Lightrail.POS" pl={0} />
                        </MenuItem>
                    </Menu>
        </span>
    );
}

// function Menus() {
//     return (
//         <React.Fragment>
//             <Helmet title="Menus" />
//             <Typography variant="h3" gutterBottom display="inline">
//                 Menus
//             </Typography>
//
//             <Breadcrumbs aria-label="Breadcrumb" mt={2}>
//                 <Link component={NavLink} to="/">
//                     Dashboard
//                 </Link>
//                 <Link component={NavLink} to="/">
//                     Components
//                 </Link>
//                 <Typography>Menus</Typography>
//             </Breadcrumbs>
//
//             <Divider my={6} />
//
//             <Grid container spacing={6}>
//                 <Grid item xs={12} md={6}>
//                     <SimpleMenu />
//                     <TransitionMenu />
//                 </Grid>
//                 <Grid item xs={12} md={6}>
//                     <IconMenu />
//                     <SelectedMenu />
//                 </Grid>
//             </Grid>
//         </React.Fragment>
//     );
// }

export default AppMenu;
