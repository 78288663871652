
const idSize = 8;
const charset = [
    "0","1","2","3","4","5","6","7","8","9",
    "A","B","C","D","E","F","G","H","J","K","L","M","N","P","Q","R","S","T","U","V","W","X","Y","Z"
];
const charString = "0123456789ABCDEFGHJKLMNPQRSTUVWXYZ";
const charBase = 34;
const positionWeights = [];

for (let i=0; i<=idSize; i++)
    positionWeights[i] = charBase ** i;

class LidGenerator {
    getLIDFromValue(value) {
        let stringBuilder = "";

        for (let i = idSize; i >= 1; i--) {
            let truncValue = Math.trunc(value / positionWeights[i - 1]);
            stringBuilder += charset[truncValue];
            if (truncValue > 0)
                value = value % (positionWeights[i - 1]);
        }

        return stringBuilder;
    }

    getValueFromText(text) {
        // convert {TEXT} to numeric value
        let stringValue = 0;

        for(let i = 0; i < text.length; i ++) {
            const chr = text.charAt(i);
            const charValue = charString.indexOf(chr);
            const charWeight = positionWeights[text.length - i - 1];
            stringValue += charValue * charWeight;
        }
        console.log("Starting value: " + stringValue);
        return stringValue;
    }

    getLids(startId, idCount, lhmPrefix, lhmDivision, lhmSubDivision) {
        if (!startId) {
            return "Error: {START_ID} is a required parameter";
        }
        if (!idCount) {
            return "Error: {ID_COUNT} is a required parameter";
        }
        if (!lhmPrefix) {
            return "Error: {LHM_PREFIX} is a required parameter";
        }
        if (!lhmDivision) {
            return "Error: {LHM_DIVISION} is a required parameter";
        }
        if (!lhmSubDivision) {
            return "Error: {LHM_SUBDIVISION} is a required parameter";
        }

        let lids = [];

        const startValue = this.getValueFromText(startId);
        for(let i = 0; i < idCount; i++) {
            let lid = this.getLIDFromValue(startValue + i);
            let barcodeString = lhmPrefix + lhmDivision + " " + lhmSubDivision +  " " + lid;
 //           console.log(barcodeString + "," + (startValue + i));
            lids.push(
                {
                    idPrefix: lhmPrefix,
                    division: lhmDivision,
                    subDivision: lhmSubDivision,
                    sku: lid,
                    lid: barcodeString
                }
            )
        }
        return(lids);
    }
}

module.exports = new LidGenerator();