// import AppSettings from "../app.json";
import {
    Sliders
} from "react-feather";

// const appPath = AppSettings.pathPrefix;
//
// const getPathBase = () => {
//     return "/" + appPath;
// }

// const navItems = [
//     {
//         title: "Beacon",
//         pages: [
//             {
//                 href: getPathBase(),
//                 icon: Sliders,
//                 title: "Beacon",
//                 children: [
//                     {
//                         href: getPathBase + "/default",
//                         title: "Main",
//                     },
//                     {
//                         href: getPathBase + "/analytics",
//                         title: "To-Do",
//                     },
//                     {
//                         href: getPathBase + "/saas",
//                         title: "Notepad",
//                     },
//                 ],
//             }
//         ]
//     }
// ];

// const navItems = [
//     {
//         title: "Beacon",
//         pages: [
//             {
//                 href: getPathBase + "/default",
//                 icon: Sliders,
//                 title: "Main"
//             },
//             {
//                 href: getPathBase + "/analytics",
//                 title: "TO-DO"
//             },
//             {
//                 href: getPathBase + "/saas",
//                 title: "Notepad"
//             }
//         ]
//     }
//     ];
//
// export default navItems;

const getMenuItems = (appSettings) => {
    const appPath = appSettings.pathPrefix;
    return [
        {
            title: "Beacon",
            pages: [
                {
                    href: "/" + appPath + "/default",
                    icon: Sliders,
                    title: "Main"
                },
                {
                    href: "/" + appPath + "/analytics",
                    title: "TO-DO"
                },
                {
                    href: "/" + appPath + "/saas",
                    title: "Notepad"
                }
            ]
        }
    ];
}

export default getMenuItems;
