import React from "react";
import {TextField} from "@mui/material";

let barcodeControlCounter = 0;

export default function SvgLabelBarcode(svgElementId, registerSvgElement) {
    barcodeControlCounter++;
    //
    // const defaultCheckedState = true;
    // const [checked, setChecked] = useState(defaultCheckedState);

    // console.log("BarcodeSettings: " + barcodeConfiguration);

    // useEffect(() => {
    //     console.log("BarcodeSettings loaded");
    // });
    function barcodeText_onChange(e) {
        console.log("barcodeText_onChange");

    }

    return (
        <span>
            <br/>
            <TextField
                m={2}
                required
                id={"svgLabelBarcode-" + barcodeControlCounter}
                label="Barcode Text"
                defaultValue="Enter barcode text..."
                onChange={barcodeText_onChange}
            />
        </span>
    );
}