import {Button} from "@mui/material";
import React from "react";
import {htmlElementId} from "../views/LabelTemplateEditor";


export default function SvgLabelTemplateSettings(svgElementId) {

    let showSvgData_onClick = () => {
        let ele = document.getElementById(htmlElementId);
        console.log(ele.innerHTML);
    }

    function relocateSVG_onClick() {
        let barcodeSvg = document.getElementById("barcode");
        if(barcodeSvg !== undefined) {
            barcodeSvg.setAttributeNS(null, "x", "50");
            barcodeSvg.setAttributeNS(null, "y", "50");
        }
    }

    return (
        <span>
            <br />
            Template Name: ..............<br/>
            Template Aspect Ration: .........<br/>
            Template Resolution: 200 pixels <br />
            ReactDOMElementId: {svgElementId}
            <br/>
            <br/>
            <Button
                mr={2}
                variant="contained"
                color="primary"
                onClick={showSvgData_onClick}
            >
                Show SVG Data
            </Button>
            &nbsp;
            <Button
                mr={2}
                variant="contained"
                color="primary"
                onClick={relocateSVG_onClick}
            >
                Relocate SVG
            </Button>
        </span>
    )
}