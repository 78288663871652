// import AppSettings from "../app.json";
import {
    Sliders
} from "react-feather";

const getMenuItems = (appSettings) => {
    const appPath = appSettings.pathPrefix;
    return [
        {
            title: "ID Manager",
            pages: [
                {
                    href: "/" + appPath + "/main",
                    icon: Sliders,
                    title: "LID Generator"
                },
                {
                    href: "/" + appPath + "/template-editor",
                    icon: Sliders,
                    title: "Label Template Editor"
                },
                {
                    href: "/" + appPath + "/saas",
                    title: "Notepad"
                }
            ]
        }
    ];
}

export default getMenuItems;