import React from "react";
import {TextField} from "@mui/material";

let textComponentCounter = 0;

// const defaultTextElementSettings = {
//     textContent: "Hello World!",
//     x: 100,
//     y: 100,
//     "dominant-baseline": "middle",
//     "text-anchor": "middle"
// }


export default function TextConfiguration() {
    textComponentCounter++;
    const myElementIndex = textComponentCounter++;

    function elementName_onChange(e) {

    }

    function text_onChange() {
        console.log("Changing Check-state");
    }

    return (
        <span>
            <br/>
            <TextField
                m={2}
                required
                id={"svgLabelTextName-" + myElementIndex}
                label="Element Name"
                defaultValue="New Text"
                onChange={elementName_onChange}
            />
            <br />
            <br />
            <TextField
                m={2}
                required
                id={"svgLabelText-" + myElementIndex}
                label="Label Text"
                defaultValue="Enter text..."
                onChange={text_onChange}
            />
        </span>
    );
}