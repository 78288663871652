import React from "react";
import {Card, CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";


function LidTable({lidRecords}) {
    console.log("Mike is at LidTable.return()");
    return (
        <Card mb={6}>
            <CardContent pb={1}>
                <Typography variant="h6" gutterBottom>
                    Generated IDs
                </Typography>
            </CardContent>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID Prefix</TableCell>
                            <TableCell align="center">Division</TableCell>
                            <TableCell align="center">Subdivision</TableCell>
                            <TableCell align="center">SKU</TableCell>
                            <TableCell align="center">LID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lidRecords.map((record) => (
                            <TableRow key={record.sku}>
                                <TableCell component="th" scope="row">{record.idPrefix}</TableCell>
                                <TableCell align="center">{record.division}</TableCell>
                                <TableCell align="center">{record.subDivision}</TableCell>
                                <TableCell align="center">{record.sku}</TableCell>
                                <TableCell align="center">{record.lid}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Card>
    );
}

export default LidTable;