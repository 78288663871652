import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {Box, Breadcrumbs, Button, Card, CardContent, Divider, Tab, Tabs, Typography} from "@mui/material";
import SvgLabelTemplateSettings from "../components/SvgLabelTemplateSettings";
import SvgLabelBarcode from "../components/SvgLabelBarcode";
import TemplateSettingsRenderer from "../components/svgLabel/TemplateSettingsRenderer";
import Barcode128Renderer from "../components/svgLabel/Barcode128Renderer";
import TextConfiguration from "../components/svgLabel/TextConfiguration";
import TextRenderer from "../components/svgLabel/TextRenderer";

let _svgElementIdCounter = 0;

export const _svgElementTypes = Object.freeze({
    TemplateSettings: "TEMPLATE-SETTINGS",
    Barcode: "BARCODE",
    ProductDetails: "PRODUCT-DETAILS",
    Text: "TEXT"
});

export const _svgElementTypeRegistry = Object.freeze( {
    "TEMPLATE-SETTINGS": {
        tabComponent: SvgLabelTemplateSettings,
        svgComponent: TemplateSettingsRenderer
    },
    "BARCODE": {
        tabComponent: SvgLabelBarcode,
        svgComponent: Barcode128Renderer
    },
    "PRODUCT-DETAILS": {
        tabComponent: SvgLabelBarcode,
        svgComponent: TemplateSettingsRenderer
    },
    "TEXT": {
        tabComponent: TextConfiguration,
        svgComponent: TextRenderer
    }
});

export const htmlElementId = "svgLabelDraftboard";
export const svgTabElementPrefix = "svgSettingsElement-";
export const svgRenderElementPrefix = "svgRenderElement-";

function _svgElementFactory(elementType, tabLabel) {
    let id = _svgElementIdCounter++;
    let svgTabElementId = svgTabElementPrefix + id;
    let svgRenderElementId = svgRenderElementPrefix + id;
    console.log("_svgElementFactory");
    return (
        {
            label: tabLabel + " (" + _svgElementIdCounter + ")",
            id: id,
            svgTabElementId: svgTabElementId,
            svgRenderElementId: svgRenderElementId,
            tabComponent: new _svgElementTypeRegistry[elementType].tabComponent(svgTabElementId),
            svgComponent: new _svgElementTypeRegistry[elementType].svgComponent(svgRenderElementId)
        }
    )
}

// initialize array with the TEMPLATE_SETTINGS control
const _svgElements = [_svgElementFactory(_svgElementTypes.TemplateSettings, "Template Settings")];

export default function LabelTemplateEditor( ){
    const [value, setValue] = React.useState(0);
    const [svgElements, setSvgElements] = React.useState(_svgElements);

    const handleChange = (event, newValue) => {
        console.log("New Tab value: " + newValue)
        setValue(newValue);
    };

    function addBarcode_onClick() {
        const newElement = _svgElementFactory(_svgElementTypes.Barcode, "Barcode");
        setValue(svgElements.length);
        svgElements.push(newElement);
        // spread the array to force a rerender cycle
        setSvgElements([...svgElements]);
    }

    function addText_onClick() {
        const newElement = _svgElementFactory(_svgElementTypes.Text, "Text");
        setValue(svgElements.length);
        _svgElements.push(newElement);
        // spread the array to force a rerender cycle
        setSvgElements([..._svgElements]);
    }

    // var draftboard;

    useEffect(() => {
        let draftboard = document.getElementById(htmlElementId);
        const style = document.createElement("style");
        style.innerHTML = '.draggable { cursor: move; background-color: red;}';
        draftboard.appendChild(style);
    });

    function printTest_onClick() {
        // const svg = document.querySelector(htmlElementId);
        const svg = document.getElementById(htmlElementId);
        // convert to a valid XML source
        const as_text = new XMLSerializer().serializeToString(svg);
        // store in a Blob
        const blob = new Blob([as_text], { type: "image/svg+xml" });
        // create an URI pointing to that blob
        const url = URL.createObjectURL(blob);
        const win = window.open(url);
        // so the Garbage Collector can collect the blob
        win.onload = () => URL.revokeObjectURL(url);
    }

    return (
        <React.Fragment>
            <Helmet title="Selects"/>
            <Typography variant="h3" gutterBottom display="inline">
                Label Templates
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Typography>Editor</Typography>
            </Breadcrumbs>

            <Divider my={6}/>
            <br/>
            <br/>
            <Card mb={6}>
                <CardContent>
                    <br />
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                {svgElements.map((svgElement) => (
                                    <Tab
                                        key={"svgElementTab-" + svgElement.id}
                                        id={"simple-tab-" + svgElement.id}
                                        label={svgElement.label}
                                        aria-controls={"simple-tabpanel-" + svgElement.id}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        {svgElements.map((svgElement) => (
                            <div
                                key={"svgElementPanel-" + svgElement.id}
                                role="tabpanel"
                                hidden={value !== svgElements.indexOf(svgElement)}
                                id={"simple-tabpanel-" + svgElement.id}
                                aria-labelledby={"simple-tab-" + svgElement.id}
                            >
                                {value === svgElements.indexOf(svgElement) && (
                                    <Box sx={{ p: 3 }}>
                                        <Typography>{svgElement.tabComponent}</Typography>
                                    </Box>
                                )}
                            </div>
                        ))}
                    </Box>
                </CardContent>
            </Card>
            <br/>
            <Button mr={2}
                    variant="contained"
                    color="primary"
                    onClick={addBarcode_onClick}
            >
                Add Barcode
            </Button>
            &nbsp;
            <Button mr={2}
                    variant="contained"
                    color="primary"
                // onClick={generateSvgImage_onClick}
            >
                Add Product Detail
            </Button>
            &nbsp;
            <Button mr={2}
                    variant="contained"
                    color="primary"
                    onClick={addText_onClick}
            >
                Add Text
            </Button>
            &nbsp;
            <Button mr={2}
                    variant="contained"
                    color="primary"
                    onClick={printTest_onClick}
            >
                Print (new tab)
            </Button>
            <br/>
            <br/>
            <Card mb={6}>
                <CardContent>
                    <div>DraftingBoard Div</div>
                    <svg id={htmlElementId}
                         viewBox="0 0 500 500"
                         style={{
                             borderWidth: 2,
                             borderStyle: "solid"
                         }}
                    >
                        {svgElements.map((svgElement) => svgElement.svgComponent)}
                    </svg>
                </CardContent>
            </Card>
        </React.Fragment>
    );
}








//
// import React, {useState, Component, useEffect, useMemo} from "react";
// import styled from "@emotion/styled";
// import {
//     Breadcrumbs,
//     Button as MuiButton,
//     Card as MuiCard,
//     CardContent,
//     Divider,
//     TextField,
//     Typography,
//     Tabs,
//     Tab,
//     Box, FormControlLabel, Checkbox
// } from "@mui/material";
// import {Helmet} from "react-helmet-async";
// import JsBarcode from "jsbarcode";
// import { spacing } from "@mui/system";
// import PropTypes from 'prop-types';
// import { useTheme } from '@mui/material/styles';
// import BarcodeSettings from "../components/BarcodeSettings";
// import ProductLabelName from "../components/ProductLabelName";
//
// const Card = styled(MuiCard)(spacing);
// const Button = styled(MuiButton)(spacing);
//
// const defaultLabelTemplateConfiguration = {
//     // productLabelNameSettings: {
//     //     id: "productLabelName",
//     //     labelobject: "true",
//     //     x: 50,
//     //     y: 150,
//     //     "dominant-baseline": "middle",
//     //     "text-anchor": "middle",
//     //     class: "draggable",
//     //     visible: true
//     // },
//     barcodeConfiguration: {
//         margin: 0,
//         visible: true
//     }
// };
//
// const labelProductData = {
//     barcode: {
//         text: "Barcode LID"
//     },
//     productLabelName: {
//         text: "Product Label Name"
//     }
// }
//
// function CustomTabPanel(props) {
//     const { children, value, index, ...other } = props;
//
//     return (
//         <div
//             role="tabpanel"
//             hidden={value !== index}
//             id={`simple-tabpanel-${index}`}
//             aria-labelledby={`simple-tab-${index}`}
//             {...other}
//         >
//             {value === index && (
//                 <Box sx={{ p: 3 }}>
//                     <Typography>{children}</Typography>
//                 </Box>
//             )}
//         </div>
//     );
// }
//
// CustomTabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.number.isRequired,
//     value: PropTypes.number.isRequired,
// };
//
// function a11yProps(index) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }
//
// function MainView() {
//     const SVG_NS = "http://www.w3.org/2000/svg";
//     const theme = useTheme();
//
//     const [value, setValue] = React.useState(0);
// //     const [labelNameConfiguration, setLabelNameConfiguration] = useState(defaultLabelTemplateConfiguration.productLabelNameSettings);
//     const [barcodeConfiguration, setBarcodeConfiguration] = React.useState(defaultLabelTemplateConfiguration.barcodeConfiguration)
//
//     const barcodeSettings = useMemo(() => {
//         return <BarcodeSettings barcodeConfiguration={barcodeConfiguration} />
//     }, []);
//
//     const productLabelName = useMemo(() => {
//         return <ProductLabelName labelProductData={labelProductData} />
//     }, []);
//
//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//     };
//
//     var mouseDragOrigin = {},
//         dragElement,
//         layoutBoard;
//
//     useEffect(initializeLayoutBoard);
//     function initializeLayoutBoard() {
//         layoutBoard = document.getElementById("layoutBoard");
//         let style = document.createElement("style");
//         style.innerHTML = '.draggable { cursor: move } ';
//         style.innerHTML += '.draggable:hover { outline: 1px solid red } ';
//         layoutBoard.appendChild(style);
//
//         // TODO: is Barcode a requirement for ALL labels?
//         console.log("initializeLayoutBoard() called");
//         JsBarcode("#barcode", labelProductData.barcode.text);
//         drawProductLabelName();
//     }
//     function getMousePositionInSVG(evt) {
//         let CTM = layoutBoard.getScreenCTM();
//         return {
//             x: (evt.clientX - CTM.e) / CTM.a,
//             y: (evt.clientY - CTM.f) / CTM.d
//         };
//     }
//
//     function drawProductLabelName(draftboard) {
//         let text = document.createElementNS(SVG_NS, "text");
//         for (let name in defaultLabelTemplateConfiguration.productLabelName) {
//             if (defaultLabelTemplateConfiguration.productLabelName.hasOwnProperty(name)) {
//                 text.setAttributeNS(null, name, defaultLabelTemplateConfiguration.productLabelName[name]);
//             }
//         }
//         text.textContent = labelProductData.productLabelName.text;
//         text.onmouseup =  group_onMouseUp;
//         text.onmousedown = group_onMouseDown;
//         layoutBoard.appendChild(text);
//         return text;
//     }
//
//     let generateSvgImage_onClick = () => {
//         JsBarcode("#barcode", labelProductData.barcode.text);
//         drawProductLabelName();
//     }
//
//     let barcodeText_onChange = (e) => {
//         labelProductData.barcode.text = e.target.value;
//     }
//
//     let showSvgData_onClick = () => {
//         let ele = document.getElementById("layoutBoard");
//         console.log("draftboard: " + ele.innerHTML);
//     }
//
//     function group_onMouseMove(e) {
//         let mousePosition = getMousePositionInSVG(e)
//         e.preventDefault();
//         if(!dragElement) return;
//         let deltaX = mousePosition.x - mouseDragOrigin.x;
//         let deltaY = mousePosition.y - mouseDragOrigin.y;
//         mouseDragOrigin.x = mousePosition.x;
//         mouseDragOrigin.y = mousePosition.y;
//         let newX = (parseFloat(dragElement.getAttributeNS(null, "x")) + deltaX).toString();
//         let newY = (parseFloat(dragElement.getAttributeNS(null, "y")) + deltaY).toString();
//         dragElement.setAttributeNS(null, "x", newX);
//         dragElement.setAttributeNS(null, "y", newY);
//     }
//
//     function group_onMouseDown(e) {
//         if (e.target.getAttributeNS(null, "labelobject")) {
//             dragElement = e.target;
//         } else if (e.target.parentNode.getAttributeNS(null, "labelobject")) {
//             dragElement = e.target.parentNode;
//         }
//         e.target.style.cursor = "move";
//         mouseDragOrigin = getMousePositionInSVG(e);
//         e.target.onmousemove = group_onMouseMove;
//     }
//
//     function group_onMouseUp(e) {
//         e.target.style.cursor = "default";
//         e.target.onmousemove = undefined;
//         dragElement = undefined;
//     }
//
//     function relocateSVG_onClick() {
//         let barcodeSvg = document.getElementById("barcode");
//         barcodeSvg.setAttributeNS(null, "x", "50");
//         barcodeSvg.setAttributeNS(null, "y", "50");
//     }
//
//     function draftingBoard() {
//         const dragListeners = [];
//
//         function registerDragListener(svgElementId, callback) {
//
//         }
//
//         const props = {
//             registerDragListener: registerDragListener,
//
//         }
//
//         return(
//             <span>
//                 <div>DraftingBoard Div</div>
//                 <svg id="layoutBoard"
//                      viewBox="0 0 500 500"
//                      style={{
//                          borderWidth: "2px",
//                          borderStyle: "solid"
//                      }}
//                 >
//                     <svg id="barcode"
//                         labelobject="true"
//                          x="100"
//                          y="100"
//                          className="draggable"
//                          onMouseUp={group_onMouseUp}
//                          onMouseDown={group_onMouseDown}
//                     >
//                     </svg>
//                 </svg>
//             </span>
//         );
//     }
//
//     const DraftingBoard = React.memo(draftingBoard);
//
//     return (
//         <React.Fragment>
//             <Helmet title="Selects"/>
//             <Typography variant="h3" gutterBottom display="inline">
//                 Lighthouse ID Management
//             </Typography>
//
//             <Breadcrumbs aria-label="Breadcrumb" mt={2}>
//                 <Typography>Image Generator</Typography>
//             </Breadcrumbs>
//
//             <Divider my={6}/>
//             <br/>
//
//             <Card mb={6}>
//                 <CardContent>
//                     <Box sx={{ width: '100%' }}>
//                         <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//                             <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
//                                 <Tab label="Barcode" {...a11yProps(0)} />
//                                 <Tab label="Product Name" {...a11yProps(1)} />
//                                 <Tab label="Item Three" {...a11yProps(2)} />
//                             </Tabs>
//                         </Box>
//                         <CustomTabPanel value={value} index={0}>{barcodeSettings}</CustomTabPanel>
//                         <CustomTabPanel value={value} index={1}>{productLabelName}
//                             {/*<br/>*/}
//                             {/*<TextField*/}
//                             {/*    m={2}*/}
//                             {/*    required*/}
//                             {/*    id="productLabelName"*/}
//                             {/*    label="Product Label Name"*/}
//                             {/*    defaultValue="Blue Argyle Socks"*/}
//                             {/*    onChange={productLabelName_onChange}*/}
//                             {/*/>*/}
//                         </CustomTabPanel>
//                         <CustomTabPanel value={value} index={2}>
//                             Item Three
//                         </CustomTabPanel>
//                     </Box>
//                 </CardContent>
//             </Card>
//             <Button
//                 mr={2}
//                 variant="contained"
//                 color="primary"
//                 onClick={generateSvgImage_onClick}
//             >
//                 Generate SVG Image
//             </Button>
//             <Button
//                 mr={2}
//                 variant="contained"
//                 color="primary"
//                 onClick={showSvgData_onClick}
//             >
//                 Show SVG Data
//             </Button>
//             <Button
//                 mr={2}
//                 variant="contained"
//                 color="primary"
//                 onClick={relocateSVG_onClick}
//             >
//                 Relocate SVG
//             </Button>
//             <br/><br/>
//             <Card mb={6}>
//                 <CardContent>
//                     <DraftingBoard />
//                 </CardContent>
//             </Card>
//         </React.Fragment>
//     );
// }
//
// export default MainView;
