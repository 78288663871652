class SvgDraggable {
    #mouseDragOrigin;
    #dragElement;
    #draftboard;

    constructor(draftboardId) {
        this.#draftboard = document.getElementById(draftboardId)
    }

    getMousePositionInSVG = (evt) => {
        let CTM = this.#draftboard.getScreenCTM();
        return {
            x: (evt.clientX - CTM.e) / CTM.a,
            y: (evt.clientY - CTM.f) / CTM.d
        };
    }
    group_onMouseEnter = (e) => {
        e.target.style.outline = "1px solid red";
    }

    group_onMouseLeave = (e) => {
        e.target.style.outline = undefined;
    }

    group_onMouseMove = (e) => {
        let mousePosition = this.getMousePositionInSVG(e);
        e.preventDefault();
        if(!this.#dragElement) return;
        let deltaX = mousePosition.x - this.#mouseDragOrigin.x;
        let deltaY = mousePosition.y - this.#mouseDragOrigin.y;
        // move the mouse origin so that the delta measurement does not compound
        // mouseDragOrigin.x = mousePosition.x;
        // mouseDragOrigin.y = mousePosition.y;
        // DRAG-ELEMENT is a CHILD of a GROUP -- so we have to move ALL SIBLINGS in unison
        if(deltaX && deltaY) {
            this.#dragElement.parentElement.childNodes.forEach((child) => {
                // let xforms = child.getAttribute('transform');
                // let parts  = /translate\(\s*([^\s,)]+)[ ,]([^\s,)]+)/.exec(xforms);
                // if(parts) {
                //     deltaX += parts[1];
                //     deltaY += parts[2];
                // }

                // let transforms = child.transform.baseVal;
                // if(transforms.length) {
                //     let transform = transforms.getItem(0);
                //     deltaX += transform.matrix.e;
                //     deltaY += transform.matrix.f;
                // }

                child.setAttributeNS(
                    null,
                    "transform",
                    "translate(" + deltaX + "," + deltaY + ")");
            })
        }
    }

    group_onMouseDown = (e) => {
        this.#dragElement = e.target;
        e.target.style.cursor = "move";
        this.#mouseDragOrigin = this.getMousePositionInSVG(e);
        e.target.onmousemove = this.group_onMouseMove;
    }

    group_onMouseUp = (e) => {
        e.target.style.cursor = "default";
        e.target.mouseMove = undefined;
        this.#dragElement = undefined;
    }
}

export default SvgDraggable;