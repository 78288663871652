import React from "react";

export default function TemplateSettingsRenderer(props) {
    const [id] = props;

    return (
        <text
            key={id}
        >
            Hello from TemplateSettingsRenderer!
        </text>
    )
}

