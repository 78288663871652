import React from "react";
import JsBarcode from "jsbarcode";
import {htmlElementId} from "../../views/LabelTemplateEditor";
import SvgDraggable from "./SvgDraggable";

export default function Barcode128Renderer(elementId) {
    let labelText = "Hello Earth!"
    const svgDraggable = new SvgDraggable(htmlElementId);

    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    JsBarcode(svg, labelText);

    return (
             <svg
                 key={elementId }
                 id={elementId }
                 labelobject="true"
                 x="100"
                 y="100"
                 className="draggable"
                 onMouseUp={svgDraggable.group_onMouseUp}
                 onMouseDown={svgDraggable.group_onMouseDown}
                 dangerouslySetInnerHTML={{ __html: svg.innerHTML}}
             />
    )
}