import SvgDraggable from "./SvgDraggable";
import {htmlElementId} from "../../views/LabelTemplateEditor";
import React from "react";


export default function TextRenderer(elementId) {
    let labelText = "Hello Earth!"
    const svgDraggable = new SvgDraggable(htmlElementId);

    return (
        <text
            key={elementId}
            id={elementId }
            labelobject="true"
            x="100"
            y="100"
            className="draggable"
            onMouseUp={svgDraggable.group_onMouseUp}
            onMouseDown={svgDraggable.group_onMouseDown}
        >
            {labelText}
        </text>
    )
}