
import React, {useState} from "react";
import {
    Breadcrumbs,
    Divider,
    Typography
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import LidsTable from "../components/LidsTable";
import LidsForm from "../components/LidsForm";

// const CustomTableCell = styled(TableCell)`
//   &.${tableCellClasses.head} {
//     background: ${(props) => props.theme.palette.common.black};
//     color: ${(props) => props.theme.palette.common.white};
//   }
//   &.${tableCellClasses.body} {
//     font-size: 14px;
//   }
// `;
//
// const CustomTableRow = styled(TableRow)`
//   &:nth-of-type(odd) {
//     background-color: rgba(0, 0, 0, 0.025);
//   }
// `;

function MainView() {
    const[lidRecords, setLidRecords] = useState([
        {
            idPrefix: "",
            division: "",
            subDivision: "",
            sku: "",
            lid: "",
            elementId: ""
        }
    ]);

    return (
            <React.Fragment>
                <Helmet title="Selects"/>
                <Typography variant="h3" gutterBottom display="inline">
                    Lighthouse LID Management
                </Typography>

                <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                    <Typography>Generator</Typography>
                </Breadcrumbs>

                <Divider my={6}/>
                <br/>

                <LidsForm updateRecordsCallback={setLidRecords}/>
                <br/>

                <LidsTable lidRecords={lidRecords}/>
            </React.Fragment>
    );
}

export default MainView;