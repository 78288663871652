
import beacon from "../applications/beacon/App";
import lidManager from "../applications/lidManager/App";

const appDirectory = {};
const appRegistry = [
        beacon,
        lidManager
    ];

for(let i=0; i<appRegistry.length; i++) {
    appDirectory[appRegistry[i].getPath()] = appRegistry[i];
}
const getApplications = () => {
    return appRegistry;
}

const getActiveApplication = () => {
    let path = window.location.pathname;
    let pathTokens = path.split("/");

    if (pathTokens[1]) {
        return appDirectory[pathTokens[1]];
    }
    return undefined;
}

const getActiveMenuItems = () => {
    let activeApp = getActiveApplication();
    if(activeApp) {
        return activeApp.getMenuItems();
    }
}

const AppRegistry = {
    getApplications: getApplications,
    getActiveApplication: getActiveApplication,
    getActiveMenuItems: getActiveMenuItems
}

export default AppRegistry;